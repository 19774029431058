const ErrorPage = () => {
  return (
    <div
      className="card mx-auto mt-3"
      style={{ width: "50%", backgroundColor: "aquamarine" }}
    >
      <div className="card-body">
        <h1>Error</h1>
      </div>
    </div>
  );
};

export default ErrorPage;
